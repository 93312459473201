<template>
  <div class="legal-wrapper">
      <h3>CONDICIONES DE USO</h3>
      <br><br>
      <div>

        <p>
          Antes de completar el formulario de registro para poder inscribirse en la plataforma de formación de CyberAcademy+ (Telefónica Cybersecurity & Cloud Tech, S.L. con C.I.F. B-01.636.760  y domicilio social en Ronda de la Comunicación s/n, Distrito Telefónica, Edificio Oeste 3, 28050, Madrid (España), le informamos de que las empresas del Grupo Telefónica tienen el compromiso de respetar la privacidad de los usuarios y el secreto y seguridad de los datos personales, en cumplimiento de lo establecido en el reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos (Reglamento General de Protección de Datos), así como en la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales.
        </p>

<div class="princ-wrapper">
  <h4>NUESTROS PRINCIPIOS</h4>

  <p>Tu privacidad y la seguridad de tus datos son nuestra prioridad. Siempre lo ha sido y forma parte de nuestro ADN. Por ello, respetamos tu privacidad y tu derecho a controlar tus datos personales. Aquí te contamos los principios que aplicamos cuando utilizamos tus datos y que rigen toda nuestra política de privacidad.</p>

  <div style="background-image: url(/media/img/icons/icon_transparency.png); background-position: 0px 4px;filter: brightness(0) invert(1); background-repeat: no-repeat;">
    <h5>Transparencia:</h5>
    <p>
      Somos 100% transparentes contigo respecto a los datos que recogemos sobre ti y te explicamos por qué los utilizamos y para qué finalidades. No vamos a tratar tus datos de una forma inesperada, oscura o abusiva.
    </p>
  </div>

  <div style="background-image: url(/media/img/icons/icon_control.png); background-position: 0px 25px; filter: brightness(0) invert(1); background-repeat: no-repeat;">
    <h5>Control:</h5>
    <p>
      Tú eres el único que puede controlar el uso que se hace de tus datos. Ponemos a tu disposición las herramientas para que puedas decidir en todo momento cómo quieres que tratemos tus datos, hasta cuándo y cómo puedes acceder y actualizar tu información personal.
    </p>
  </div>

  <div style="background-image: url(/media/img/icons/icon_seguridad.png); background-position: 15px 25px; filter: brightness(0) invert(1); background-repeat: no-repeat;">
    <h5>Seguridad:</h5>
    <p>Nos preocupamos por garantizar la seguridad, el secreto y la confidencialidad de tus datos, comunicaciones e información personal. Adoptamos las más exigentes y robustas medidas de seguridad para evitar su pérdida, alteración, mal uso o acceso sin tu autorización.</p>
  </div>

</div>

<h3 class="mt-5" id="privacy">INFORMACIÓN DETALLADA DE LA POLÍTICA DE PRIVACIDAD Y COOKIES DE CyberAcademy+</h3>

<h4>1. ¿Quién es responsable del tratamiento de tus datos como usuario de CyberAcademy+?</h4>

<h5>Resumen:</h5>
<p>TELEFÓNICA CYBERSECURITY & CLOUD TECH, S.L. es la empresa del Grupo Telefónica responsable del tratamiento de tus datos personales como usuario de la plataforma de formación CyberAcademy+</p>

<h5>Detalle:</h5>
<p>La compañía responsable del tratamiento de tus datos es:</p>

<ul>
  <li>Nombre: TELEFÓNICA CYBERSECURITY & CLOUD TECH, S.L.U. </li>
  <li>CIF: B-01.636.760</li>
  <li>Dirección: Ronda de la Comunicación s/n, Distrito Telefónica, Edificio Oeste 3, 28050, Madrid (España)</li>
  <li>Correo electrónico: <a href="mailto:info.cyberacademy.tcct@telefonica.com">info.cyberacademy.tcct@telefonica.com</a></li>
  <li>Delegado de Protección: <a href="mailto:Dpo_telefonicasa@telefonica.com">Dpo_telefonicasa@telefonica.com</a></li>
</ul>

<h4>2. ¿Qué datos tratamos sobre ti?</h4>

<h5>Resumen:</h5>
<p>Tenemos información relativa a quién eres y cómo utilizas los servicios de CyberAcademy+. </p>

<h5>Detalle:</h5>
<p>La información que tenemos sobre tí incluye datos relativos a quién eres y cómo utilizas los servicios de CyberAcademy+.</p>

<p><b>Quién eres:</b> los datos que vamos a tratar sobre ti serán solo aquellos que nos proporciones al completar nuestros formularios de registro, entre los que puede haber:</p>

<ul>
  <li>Datos de carácter identificativo: Nombre, apellidos, dirección, correo electrónico, teléfono.</li>
  <li>Datos del empleo: Empresa, cargo o departamento dentro de la empresa</li>
</ul>

<p><b>Cómo utilizas los servicios de CyberAcademy+:</b> Datos sobre el uso del servicio que hacen los usuarios.
<i>Incluye cuándo y dónde usas CyberAcademy+, durante cuánto tiempo lo usas o cómo lo usas (p.ej. qué
contenidos ves, cuáles completas, cómo navegas, si llevas mucho tiempo sin acceder a CyberAcademy+,
etc.).</i></p>

<h4>3. ¿Cómo obtenemos y de dónde proceden tus datos?</h4>

<h5>Resumen:</h5>
<p>Principalmente eres tú quien nos proporciona directamente tus datos.</p>

<h5>Detalle:</h5>
<p><b>Principalmente obtenemos tus datos directamente de tí:</b></p>

<ul>
  <li>Nos los proporcionas tú directamente al completar los diferentes formularios de registro de la web. Recuerda que la información personal que nos proporciones directamente deberá ser siempre real, veraz y estar actualizada. Pondremos a tu disposición las herramientas necesarias para que puedas actualizar o rectificar estos datos.</li>
  <li>Los observamos cuando usas CyberAcademy+ Recuerda que puedes revisar y gestionar los permisos que otorgas a CyberAcademy+ para obtener datos de tu dispositivo a través de las opciones disponibles en tu terminal (Android, iOS o web).</li>
</ul>

<h4>4. ¿Para qué y por qué utilizamos tus datos?</h4>

<h5>Resumen:</h5>
<p>Utilizamos tus datos para prestarte el servicio de CyberAcademy+. Asimismo, también los utilizamos para otras finalidades legítimas con el objetivo de comunicarnos contigo para atender una solicitud o consulta, conocer el uso que haces de CyberAcademy+ o incluso cumplir con algunas obligaciones legales que nos son aplicables.</p>

<h5>Detalle:</h5>
<h6>Tratamos tus datos para:</h6>

<ul>
  <li>Que puedas disfrutar de CyberAcademy+ y todas sus funcionalidades (prestarte el servicio).</li>
  <li>Comunicarnos y estar en contacto contigo con el objetivo de informarte acerca de los productos y servicios que sean relevantes para tí a través de diversos canales como son, por ejemplo, el email, o la propia plataforma de CyberAcademy+. Para ser transparentes contigo, nuestro objetivo es que te descargues y registres en CyberAcademy+ (si no lo has hecho ya), así como que estés contento con los servicios CyberAcademy+, los conozcas y los utilices activamente.</li>
  <li>Conocer cómo utilizas los productos y servicios CyberAcademy+ para:</li>
  <ul>
    <li>Detectar tendencias y correlaciones con el objetivo de entender y predecir de forma agregada el uso de dichos servicios y las necesidades que puedas tener como usuario.</li>
    <li>Tomar mejores decisiones de negocio como, por ejemplo, cómo mejorar los productos y servicios CyberAcademy+, así como tu experiencia, o cómo hacer más efectivas las acciones de comunicación que te indicamos en el punto anterior.</li>
    <li>Elaborar reportes con datos agregados sobre la evolución en el número de usuarios activos o como se utilizan determinadas funcionalidades.</li>
    <li>Comprobar si has realizado una acción concreta a través de CyberAcademy+.</li>
    <li>Comprobar si has realizado una acción concreta a través de CyberAcademy+.</li>
    <li>Prevenir y detectar actividades fraudulentas, malintencionadas o ilegales (especialmente cuando esto pueda afectar a otros usuarios).</li>
  </ul>
  <li>Realizar encuestas con el objetivo de preguntarte y conocer tu opinión acerca de la CyberAcademy+ o de sus servicios y funcionalidades.</li>
  <li>Colaborar y responder órdenes o requerimientos de las autoridades.</li>
  <li>Denunciar o poner en conocimiento de las autoridades acciones fraudulentas, malintencionadas o presuntamente ilegales.</li>
  <li>Defendernos frente a demandas, denuncias y/o reclamaciones de cualquier clase.</li>
  <li>Garantizar la seguridad física, de las redes y de las tecnologías de la información que utilizamos para prestarte nuestros servicios y productos.</li>
</ul>

<p>Por último, es posible que en el futuro necesitemos tratar tus datos para finalidades adicionales con el objetivo de seguir innovando y ofrecerte servicios de valor añadido.</p>

<p>No te preocupes, en ese caso siempre analizaremos si dichas finalidades son compatibles con tus expectativas y con los principios que rigen nuestra política de privacidad y cookies.</p>

<p>En cualquier caso, adoptaremos previamente todas las salvaguardas necesarias para asegurar dicha compatibilidad como, por ejemplo, medidas transparencia, control o de seguridad adicionales.</p>

<h6>Utilizamos tus datos para las anteriores finalidades porque:</h6>

<ul>
  <li>Los necesitamos para que puedas disfrutar de la CyberAcademy+ y sus funcionalidades de conformidad con sus condiciones de uso.</li>
  <li>Los necesitamos para cumplir con determinadas obligaciones legales y regulatorias (p.ej. obligación de colaborar y responder requerimientos de las autoridades).</li>
  <li>Algunas de las finalidades son necesarias para los intereses legítimos de negocio de TELEFÓNICA CYBERSECURITY & CLOUD TECH, S.L aunque siempre te podrás oponer a los mismos (p.ej. comunicarnos y estar en contacto contigo para informarte acerca de productos y servicios CyberAcademy+)</li>
  <li>Porque nos has dado tu permiso para que tratemos tus datos para ciertas finalidades (p.ej. aquellos permisos que otorgas durante el registro para que podamos acceder a información de tu dispositivo).</li>
</ul>

<h4>5. ¿Durante cuánto tiempo conservamos tus datos?</h4>

<h5>Resumen:</h5>
<p>Existen determinados motivos que nos legitiman para conservar tus datos, aunque sólo los conservaremos y trataremos mientras sean necesarios para las finalidades indicadas en el punto 4 de esta política. El periodo de conservación concreto dependerá del tipo de datos y para qué y por qué se utilizan.</p>

<h5>Detalle:</h5>
<p>Con carácter general, dejaremos de conservar tus datos en el momento en que no sea necesario para continuar prestándote el servicio CyberAcademy+. No obstante, existen algunas excepciones a esta regla general que te explicamos a continuación.</p>

<p>En este sentido hay algunas finalidades que persisten y continúan incluso después de la baja de  CyberAcademy+. Concretamente, necesitamos continuar conservando algunos datos sobre tí y sobre cómo utilizabas  CyberAcademy+ para:</p>

<ul>
  <li>Tener un histórico sobre cómo utilizabas CyberAcademy+.</li>
  <li>Cumplir determinadas obligaciones legales de conservación de datos aplicables a TELEFÓNICA CYBERSECURITY & CLOUD TECH, S.L</li>
  <li>Colaborar con las autoridades y responder sus requerimientos de información.</li>
  <li>Defendernos frente a demandas, denuncias y/o reclamaciones.</li>
  <li>Prevenir fraudes, abusos o acciones ilícitas.</li>
  <li>Probar y verificar que tuviste una cuenta de usuario en CyberAcademy+.</li>
</ul>

<p>No obstante, durante este periodo de tiempo adicional aplicaremos toda una serie de medidas y
salvaguardas adicionales para asegurar la protección de tu información y tu privacidad. Este tipo de medidas
podrán incluir, por ejemplo, la separación funcional, seudonimización, y/o la encriptación de tus datos e
información.</p>

<p>Asimismo, tomaremos todas las medidas razonables para garantizar que tus datos se rectifiquen o supriman
cuando sean inexactos.</p>

<h4>6. ¿Quién tiene acceso a tus datos (además de TELEFÓNICA CYBERSECURITY & CLOUD TECH, S.L.)?</h4>

<h5>Resumen:</h5>
<p>Además de TELEFÓNICA CYBERSECURITY & CLOUD TECH, S.L tienen acceso a tus datos personales: nuestros proveedores y colaboradores, otras empresas del Grupo Telefónica, así como, en su caso, las autoridades con las que colaboramos y nos relacionamos. El acceso a tus datos que proporcionamos a estos terceros es siempre para finalidades lícitas y sólo durante el periodo de tiempo estrictamente necesario para ello.</p>

<h5>Detalle:</h5>
<p>Necesitamos compartir tus datos con determinados terceros que te indicamos a continuación, siempre en relación con las finalidades concretas para las que tratamos tus datos, descritas en el punto 4, y durante el periodo de tiempo estrictamente necesario para ello.</p>

<p>En concreto, esto implica dar acceso a tus datos a:</p>

<ul>
  <li>Nuestros proveedores y colaboradores, así como las empresas que estos subcontraten, los cuales nos ayudan a prestarte los servicios de CyberAcademy+, a cumplir con las finalidades descritas o a comunicarnos y estar en contacto contigo, así como a realizar encuestas y a conocer cómo utilizas CyberAcademy+.. Estos proveedores son:</li>
  <ul>
    <li>Microsoft, como proveedor de la herramienta Office 365</li>
  </ul>
  <li>Autoridades (p.ej. cuerpos y fuerzas de seguridad del estado u órganos judiciales), nacionales y/o internacionales, que requieran acceder legalmente a tu información, así como, en su caso, aquellas otras Autoridades con las que necesitemos compartir tu información para defendernos frente a posibles demandas, denuncias y/o reclamaciones de cualquier clase, así como para poner en su conocimiento acciones fraudulentas, malintencionadas o presuntamente ilegales.</li>
</ul>

<p>En cualquier caso, TELEFÓNICA CYBERSECURITY & CLOUD TECH, S.L siempre garantiza el máximo nivel de protección en el acceso que estos terceros tengan a tus datos e información, por ejemplo, alcanzando acuerdos de tratamiento de datos con los mismos o mediante el establecimiento de medidas de salvaguardas apropiadas que aseguren la confidencialidad y el tratamiento seguro de tus datos.</p>

<h4>7. ¿Cuáles son tus derechos y cómo puedes controlar tus datos?</h4>

<h5>Resumen:</h5>
<p>La normativa te otorga una serie de derechos en relación a los datos e información que tratamos sobre tí. Concretamente, los derechos de acceso, rectificación, supresión, limitación, oposición, portabilidad, así como a no ser objeto de decisiones automatizadas y a retirar tu consentimiento en cualquier momento. Algunos de estos derechos podrás ejercerlos directamente a través de la web CyberAcademy+ y otros tendrás que escribirnos a la dirección cyberacademy@telefonica.com para ejercitarlos.</p>

<h5>Detalle:</h5>
<p>Puedes ejercer los siguientes derechos de protección de datos frente a TELEFÓNICA CYBERSECURITY & CLOUD TECH, S.L:</p>

<ul>
  <li><b>Derecho de acceso:</b> derecho a solicitar el detalle de los datos que tenemos sobre ti y cómo los tratamos, así como la obtención de una copia de los mismos.</li>
  <li><b>Derecho de rectificación:</b> derecho a obtener la rectificación de tus datos inexactos o erróneos, así como a completar aquellos otros que sean incompletos.</li>
  <li><b>Derecho de supresión:</b> derecho a solicitar la eliminación o supresión de tus datos e información en determinadas circunstancias. No obstante, ten en cuenta de que hay determinadas ocasiones en las que TELEFÓNICA CYBERSECURITY & CLOUD TECH, S.L.está legalmente legitimada para continuar conservando y tratando tus datos (p.ej. para el cumplimiento de una obligación legal de conservación de datos).</li>
  <li><b>Derecho de limitación:</b> derecho a restringir o limitar el tratamiento de tus datos en determinadas circunstancias (p.ej. si aplica la supresión de datos pero, en vez de eliminarlos, prefieres que los bloqueemos y los tratemos únicamente a los efectos de su conservación ya que vas a necesitarlos posteriormente para interponer una reclamación). De nuevo, ten en cuenta que puede haber ocasiones en que TELEFÓNICA CYBERSECURITY & CLOUD TECH, S.L esté legalmente legitimada para rechazar tu solicitud de limitación.</li>
  <li><b>Derecho de oposición:</b> derecho a oponerte a TELEFÓNICA CYBERSECURITY & CLOUD TECH, S.L que trate tus datos para una finalidad concreta, en determinadas circunstancias previstas en la normativa y relacionadas con tu situación personal. En algunos casos te facilitamos opciones sencillas para oponerte directamente a través de la app (p.ej. oposición a recibir comunicaciones comerciales remitidas por TELEFÓNICA CYBERSECURITY & CLOUD TECH, S.L.) y en otros casos deberás contactar con nosotros a través del correo electrónico para que nuestro equipo de privacidad pueda evaluar tu solicitud con más detalle.</li>
  <li><b>Derecho de portabilidad de datos:</b> derecho a recibir tus datos en un formato de uso común que te permita portarlos a otra empresa, así como, en su caso, a solicitar a TELEFÓNICA CYBERSECURITY & CLOUD TECH, S.L, la transmisión directa de los mismos a dicha otra empresa cuando sea técnicamente posible. Recuerda que el derecho a la portabilidad de datos es distinto al derecho a la portabilidad de tu número de teléfono.</li>
  <li><b>Derecho a no ser objeto a decisiones automatizadas:</b> derecho a oponerte a ser objeto de una decisión basada únicamente en el tratamiento automatizado de datos (sin intervención humana), incluida la elaboración de perfiles, que produzca efectos jurídicos en tí o te afecte de una forma significativa.</li>
  <li><b>Derecho a retirar el consentimiento en cualquier momento:</b> derecho a retirar tu consentimiento en cualquier momento al tratamiento de tus datos para una finalidad concreta, cuando esté basada en dicha base legitimadora.</li>
</ul>

<p>Puedes consultar el alcance y detalle completo de estos derechos en la página web de la Agencia Española de Protección de Datos (AEPD).</p>

<p>Con carácter general, podrás ejercitar todos estos derechos (en cualquier momento y de forma gratuita) escribiendo al equipo de privacidad de TELEFÓNICA CYBERSECURITY & CLOUD TECH, S.L. a <a href="mailto:info.cyberacademy.tcct@telefonica.com">info.cyberacademy.tcct@telefonica.com</a></p>

<p>Para ello es importante tener en cuenta que:</p>

<ul>
  <li>Cuando ejercites un derecho, deberás aportar una copia de tu documento oficial de identidad (p.ej. DNI, NIE o Pasaporte) y, si es posible, envíes tu solicitud desde el mismo correo electrónico que utilizaste para registrarte en CyberAcademy+.</li>
  <li>En el caso de que decidas ejercitar alguno de estos derechos a través de un representante, será necesario aportar en la solicitud la documentación acreditativa de dicha condición (p.ej. en el caso de un representante voluntario, se podrá aportar el poder de representación en el que figuren facultades que permitan el ejercicio de tales derechos en tu nombre).</li>
  <li>Las solicitudes de ejercicio de derechos sean lo más específicas y detalladas posibles, indicando en las mismas el concreto derecho que se desea ejercer.</li>
</ul>

<p>No obstante lo anterior, podrás ejercitar tu derecho a oponerte a la recepción de comunicaciones comerciales, por cualquier canal, procedentes de TELEFÓNICA CYBERSECURITY & CLOUD TECH, S.L, con tan sólo enviarnos un email a <a href="mailto:Info.cyberacademy.tcct@telefonica.com">Info.cyberacademy.tcct@telefonica.com</a></p>

<p>Por último, recuerda que también tienes derecho a presentar una reclamación ante una Autoridad de Control de Protección de Datos (p.ej. la Agencia Española de Protección de Datos, cuyos datos de contacto puedes consultar aquí), especialmente si estimas o entiendes que TELEFÓNICA CYBERSECURITY & CLOUD TECH, S.L. no ha satisfecho correctamente el ejercicio de tus derechos de protección de datos.</p>

<h4 id="cookies">8. ¿Cómo utilizamos las cookies y cómo puedes gestionarlas?</h4>

<h5>Resumen:</h5>
<p>TELEFÓNICA CYBERSECURITY & CLOUD TECH, S.L. utiliza cookies propias y de terceros para darte un servicio más personalizado, conocer cómo usas los servicios y productos CyberAcademy+ y, en su caso, mostrarte publicidad relacionada con tus preferencias y hábitos de navegación. Si utilizas CyberAcademy+, entendemos que aceptas su uso.</p>

<h5>Detalle:</h5>
<p>Una cookie es un fichero que se descarga en tu dispositivo al acceder a determinadas páginas web y/o aplicaciones. Las cookies permiten, entre otras cosas, almacenar y recuperar información sobre tu número de visitas, hábitos de navegación o de tu dispositivo y, dependiendo de la información que contengan y de la forma en que utilice, pueden utilizarse para reconocerte como usuario.</p>

<p>Ten en cuenta que para poder utilizar CyberAcademy+ es necesario que tengas habilitadas las cookies, especialmente aquellas de carácter técnico que resultan necesarias para TELEFÓNICA CYBERSECURITY & CLOUD TECH, S.L. pueda identificarte como usuario registrado cada vez que accedas a nuestra web o aplicación. TELEFÓNICA CYBERSECURITY & CLOUD TECH, S.L. utiliza los siguientes tipos de cookies, las cuales son tratadas bien por nosotros directamente o por terceros colaboradores:</p>

<ul>
  <li><b>Cookies técnicas:</b> Son aquellas utilizadas por TELEFÓNICA CYBERSECURITY & CLOUD TECH, S.L.que permiten al usuario la navegación y la utilización de las diferentes opciones o servicios que se ofrecen como, por ejemplo, controlar el tráfico y la comunicación de datos, identificar la sesión, acceder a partes de acceso restringido, recordar los elementos que integran un pedido, realizar el proceso de compra de un pedido, realizar la solicitud de inscripción, utilizar elementos de seguridad durante la navegación o almacenar, difundir y/o compartir contenidos.</li>
  <li><b>Cookies de personalización:</b> Son aquéllas utilizadas por TELEFÓNICA CYBERSECURITY & CLOUD TECH, S.L.que permiten al usuario acceder a  CyberAcademy+ con algunas características de carácter general predefinidas en función de una serie de criterios en su dispositivo como, por ejemplo, el idioma, el tipo de navegador, la configuración regional desde donde accede, etc.</li>
  <li><b>Cookies de análisis:</b> Son aquéllas utilizadas por TELEFÓNICA CYBERSECURITY & CLOUD TECH, S.L.y por terceros colaboradores (GOOGLE ANALYTICS, GOOGLE TAG MANAGER, GOOGLE FIREBASE, AKAMAI, COMSCORE, MIXPANEL, HOTJAR, HOCKEY APPS y HUBSPOT) que permiten cuantificar el número de usuarios y así realizar la medición y análisis estadístico de la utilización y actividad que hacen los usuarios, así como elaborar perfiles de navegación de éstos para poder introducir mejoras en productos y servicios CyberAcademy+.</li>
  <li><b>Cookies publicitarias y de publicidad comportamental:</b> Son aquéllas utilizadas por TELEFÓNICA CYBERSECURITY & CLOUD TECH, S.L.y por nuestros colaboradores que gestionan los espacios que sirven publicidad de TELEFÓNICA CYBERSECURITY & CLOUD TECH, S.L.(APPSFLYER, PUBMATIC, ADSRVR, RUBICON PROJECT, SMAATO, CONNEXITY, TURN, SIMPLIFI, AFFILINET, BING ADS, BLUEKAI, DOUBLECLICK, YOUTUBE, CRITEO, MOVISTAR, GOOGLE, YAHOO, FACEBOOK, TWITTER, ROCKETFUEL, MEDIAMATH, SMART, APPNEXUS, OPENX, ADGRAVITY, SMARTCLIP, ADFORM, HUBSPOT, CRAZY EGG, PLATFORM161, ROCKET FUEL y/o THE TRADE DESK) que permiten difundir publicidad adecuada y relevante, medir la efectividad de nuestras campañas online, así como adecuar el contenido de los anuncios al perfil de navegación de cada usuario en internet.</li>
</ul>

<p>Recuerda que puedes permitir, bloquear o eliminar estas cookies cuando quieras a través de las opciones de configuración de tu dispositivo o terminal, así como de tu navegador de internet. Para ello, puedes consultar la información de soporte más actualizada de dichos dispositivos o navegadores para conocer el modo en que puedes eliminar las cookies, dado que el modo puede diferir entre distintas versiones y sistemas operativos. A modo de ejemplo:</p>

<ul>
  <li>Para Internet Explorer, pincha <a href="https://support.microsoft.com/es-es/topic/eliminar-y-administrar-cookies-168dab11-0753-043d-7c16-ede5947fc64d">aquí</a></li>
  <li>Para Mozilla Firefox, pincha <a href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias?redirectlocale=es&redirectslug=habilitar-y-deshabilitar-cookies-que-los-sitios-we">aquí</a></li>
  <li>Para Google Chrome, pincha <a href="https://support.google.com/chrome/answer/95647?hl=es">aquí</a></li>
  <li>Para Safari, pincha aquí <a href="https://support.apple.com/es-es/HT201265">aquí</a></li>
</ul>

<p>En el caso de que utilices cualquier otro navegador de Internet, te recomendamos que consultes las distintas opciones de gestión de cookies que pudiesen encontrarse disponibles en el mismo en cada momento, así como, en general, cualesquiera otras herramientas disponibles en el mercado para el bloqueo y/o administración de cookies (p.ej. <a href="https://www.ghostery.com">Ghostery</a> o <a href="https://disconnect.me">Disconnect</a>)
</p>

<p>Para más información, puedes escribirnos a <a href="mailto:info.cyberacademy.tcct@telefonica.com">info.cyberacademy.tcct@telefonica.com</a></p>

<h4>9. ¿Cómo protegemos tus datos?</h4>

<h5>Resumen:</h5>
<p>TELEFÓNICA CYBERSECURITY & CLOUD TECH, S.L garantiza la seguridad, el secreto y la confidencialidad de tus datos, comunicaciones e información personal adoptado las más exigentes y robustas medidas de seguridad, y poniendo a tu disposición herramientas y funcionalidades que te permiten controlar el acceso a tu cuenta de CyberAcademy+.</p>

<h5>Detalle:</h5>
<p>TELEFÓNICA CYBERSECURITY & CLOUD TECH, S.L.se preocupa por garantizar la seguridad, el secreto y la confidencialidad de tus datos, comunicaciones e información personal. Por eso, como parte de nuestro compromiso y en cumplimiento de la legislación vigente, hemos adoptado las más exigentes y robustas medidas de seguridad y medios técnicos para evitar su pérdida, mal uso o su acceso sin tu autorización.</p>

<p>Además, nos comprometemos a actuar con rapidez y responsabilidad en el caso de que la seguridad de tus datos pueda estar en peligro, y a informarte si fuese relevante.</p>

<h4>10. ¿Qué tienes que tener especialmente en cuenta al utilizar CyberAcademy+?</h4>

<h5>Resumen:</h5>
<p>Has te tener en cuenta determinados aspectos relativos a la baja de tu cuenta de usuario de CyberAcademy+.</p>

<h5>Detalle:</h5>
<p>Te recomendamos que tengas especialmente en cuenta lo siguientes a la hora de registrarte y utilizar CyberAcademy+:</p>

<ul>
  <li><b>Baja de tu cuenta de usuario:</b> en general, puede haber dos situaciones en las que se proceda a la desactivación técnica de tu cuenta de usuario: 1) que nos solicites voluntariamente que procedamos a dar de baja tu cuenta de usuario en CyberAcademy+ conforme se explica en el punto 7 de esta política, y 2) que desde TELEFÓNICA CYBERSECURITY & CLOUD TECH, S.L.se proceda a la desactivación técnica de tu cuenta de usuario (p.ej. en casos en los que detectemos que has incumplido las condiciones de uso del servicio). En caso de baja, perderás la información y datos que pudieses tener en CyberAcademy+, así como el derecho a utilizar cualesquiera beneficios o servicios que pudieses tener activos a la fecha de la baja y/o haber contratado con anterioridad como usuario de CyberAcademy+.</li>
</ul>
      </div>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPage'
}
</script>

<style lang="scss" scoped>

.legal-wrapper {
  padding-top: 2rem;
  width: 70%;
  margin: 0 auto;
}

h4 {
  margin-top: 4rem;
}

h5 {
  margin-top: 2rem;
}

.princ-wrapper {

  div {
    padding-left: 100px;
  }
}

a {
  color: white;
  text-decoration: underline;
}
</style>
